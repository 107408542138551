/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    br: "br",
    pre: "pre",
    code: "code",
    h2: "h2",
    blockquote: "blockquote",
    ol: "ol",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components), {YouTube, Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  if (!YouTube) _missingMdxReference("YouTube", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The linked list is everyone’s favourite data structure, ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=31g0YE61PLQ"
  }, "right?")), "\n", React.createElement(YouTube, {
    youTubeId: "31g0YE61PLQ"
  }), "\n", React.createElement(_components.p, null, "Ali Spittel has ", React.createElement(_components.a, {
    href: "https://dev.to/aspittel/thank-u-next-an-introduction-to-linked-lists-4pph"
  }, "a great writeup that answers the questions: “What are linked lists, and how to they work?”"), ".", React.createElement(_components.br), "\n", "That article implements them in JavaScript, and in Python."), "\n", React.createElement(_components.p, null, "How would that look in Rust?"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "struct Node {\n    data: i32,\n    next: Node,\n}\n")), "\n", React.createElement(_components.h2, {
    id: "expressing-nothing"
  }, "Expressing “nothing”"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "next"), " value can either be a node, or nothing.\nIn other languages, that would be expressed by not giving ", React.createElement(_components.code, null, "next"), " a value, by having it be ", React.createElement(_components.code, null, "null"), ".\nRust doesn’t have a ", React.createElement(_components.code, null, "null"), " value like many other languages do."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "The problem with null values is that if you try to use a null value as a not-null value, you’ll get an error of some kind. Because this null or not-null property is pervasive, it’s extremely easy to make this kind of error."), "\n", React.createElement(_components.p, null, "However, the concept that null is trying to express is still a useful one: a null is a value that is currently invalid or absent for some reason."), "\n", React.createElement("footer", null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/book/ch06-01-defining-an-enum.html?highlight=null#the-option-enum-and-its-advantages-over-null-values"
  }, "The Rust programming language book"))), "\n"), "\n", React.createElement(_components.p, null, "If we want to express the absence of something, we should do it explicitly.\nAn ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/option/enum.Option.html"
  }, React.createElement(_components.code, null, "Option<T>")), " does this.\nIt’s an enum with 2 variants:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "Some(T)"), " if there is something"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "None"), " if there is nothing"), "\n"), "\n", React.createElement(_components.p, null, "Our updated ", React.createElement(_components.code, null, "Node"), " struct:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    hl: "3"
  }, "struct Node {\n    data: i32,\n    next: Option<Node>,\n}\n")), "\n", React.createElement(_components.p, null, "The value for ", React.createElement(_components.code, null, "next"), " can be either ", React.createElement(_components.code, null, "None"), ", or ", React.createElement(_components.code, null, "Some(Node)"), "."), "\n", React.createElement(_components.h2, {
    id: "infinite-recursion"
  }, "Infinite recursion"), "\n", React.createElement(_components.p, null, "This doesn’t compile,\nRust has trouble with this.\nThe size of a type needs to be known at compile time."), "\n", React.createElement(_components.p, null, "While compiling, Rust tries to figure out the maximum amount of space an instance of that type can take up in memory.\nThat maximum amount of space needs to be finite."), "\n", React.createElement(_components.p, null, "For our ", React.createElement(_components.code, null, "Node"), " type, the process would go like this:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "A ", React.createElement(_components.code, null, "Node"), " takes up the space required to store an ", React.createElement(_components.code, null, "i32"), ", and an other ", React.createElement(_components.code, null, "Node"), "."), "\n", React.createElement(_components.li, null, "That other ", React.createElement(_components.code, null, "Node"), " takes up the spaces required to store an ", React.createElement(_components.code, null, "i32"), ", and yet an other ", React.createElement(_components.code, null, "Node"), "."), "\n", React.createElement(_components.li, null, "That next ", React.createElement(_components.code, null, "Node"), " takes up the spaces required to store an ", React.createElement(_components.code, null, "i32"), ", and yet an other ", React.createElement(_components.code, null, "Node"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=2VSYmGSJtCA"
  }, "Repeat ad infinitum")), "\n"), "\n", React.createElement(_components.p, null, "There’s no stop to it, this goes on forever and a variable of type ", React.createElement(_components.code, null, "Node"), " would take up an infinite amount of space in memory."), "\n", React.createElement(_components.p, null, "It’s like that song by Journey, it goes on, and on, and on, and on…"), "\n", React.createElement(YouTube, {
    youTubeId: "1k8craCGpgs",
    skipTo: {
      m: 2,
      s: 20
    }
  }), "\n", React.createElement(_components.p, null, "The Rust compiler error when trying to execute this code is very helpful."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "error[E0072]: recursive type `Node` has infinite size\n  */} src/main.rs:03:1\n   |\n83 | struct Node {\n   | ^^^^^^^^^^^ recursive type has infinite size\n84 |     data: i32,\n85 |     next: Option<Node>,\n   |           ------------ recursive without indirection\n   |\nhelp: insert some indirection (e.g., a `Box`, `Rc`, or `&`) to make `Node` representable\n   |\n85 |     next: Box<Option<Node>>,\n   |           ^^^^            ^\n")), "\n", React.createElement(_components.p, null, "We’ve defined our ", React.createElement(_components.code, null, "Node"), " type, which holds an instance of itself (in ", React.createElement(_components.code, null, "next"), ").\nIt’s a recursive type, and has a possibly infinite size."), "\n", React.createElement(_components.p, null, "The compiler then suggests to add some indirection.\n", React.createElement(_components.em, null, "indi-what?")), "\n", React.createElement(_components.p, null, "They mean instead of storing the ", React.createElement(_components.code, null, "Node"), " value (of unknown size) directly,\nstore a pointer to that value."), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box<T>")), " is great for this.\nIt’s a ", React.createElement(_components.a, {
    href: "/garden/rust-smart-pointers"
  }, "smart pointer"), " that stores data on the stack ", React.createElement(_components.em, null, "and"), " the heap.\nNearly all that data is stored on the heap.\nThe only thing that is stored on the stack is a pointer to that location on the heap.\nBecause ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box")), " is a smart pointer that implements the ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Deref.html"
  }, React.createElement(_components.code, null, "Deref")), " trait,\nwe can treat it as a regular immutable reference to that data."), "\n", React.createElement(_components.p, null, "That pointer has a fixed, known size, so we no longer have that “infinite size” problem.\nThe data that is possibly infinite in size is stored on the heap now, that’s okay."), "\n", React.createElement(_components.p, null, "Wrapping the ", React.createElement(_components.code, null, "Node"), " in a ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box")), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    hl: "3"
  }, "struct Node {\n    data: i32,\n    next: Option<Box<Node>>,\n}\n")), "\n", React.createElement(Aside, {
    variant: "success"
  }, React.createElement(_components.p, null, "That’s it!\nWe linked a list.")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "main.rs"
  }, "struct Node {\n    data: i32,\n    next: Option<Box<Node>>,\n}\n\nimpl Node {\n    fn new(num: i32) -> Node {\n        Node {\n            data: num,\n            next: None,\n        }\n    }\n\n    fn set_next(&mut self, next: Node) {\n        self.next = Some(Box::new(next));\n    }\n}\n\nlet mut one = Node::new(1);\nlet two = Node::new(2);\n\none.set_next(two);\n\nprintln!(\"{}\", one.data);\n// 1\nprintln!(\"{}\", one.next);\n// Some(Node { data: 2, next: None })\nprintln!(\"{:?}\", one);\n// Node { data: 1, next: Some(Node { data: 2, next: None }) }\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
